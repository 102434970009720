export default function mounted() {
    // this.getSerialNumber();
    this.getPayemntMode();
    this.getJournalTypes();
    this.setEntryDate();
    this.getLedgerHeads();
    this.getAccountHeads();
    this.automaticVoucherNo = '';
    this.payment_mode_type.journal_type_id = '';

    this.form.details = [{
        ledger_head_id: '',
        ledger_head_name: '',
        total_amount: 0,
        tax: 0,
        net_amount: 0,
        opening_balance: 0

    }]


    // this.getBanks();
}
