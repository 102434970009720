<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card style="padding: 10px 0px 20px">
          <v-card-title class="title" style="padding-bottom: 0px">
            <!-- <v-icon style="margin-right: 5px">message</v-icon> -->
            General Payment
            <v-spacer></v-spacer>
            <!-- <input
              type="text"
              v-mask="'####-##-##'"
              v-model="form.entry_date"
              placeholder="2075-01-01"
              class="date"
            /> -->
            <v-calendar-field
              outlined
              dense
              id-val="today-gp"
              v-model="form.entry_date"
              label="Date"
              prepend-icon="event"
            ></v-calendar-field>
          </v-card-title>
          <v-card outlined>
            <v-card-text style="margin-top: 14px">
              <v-form
                ref="form"
                @submit.prevent="store"
                @keydown.native="form.errors.clear($event.target.name)"
                @keyup.enter="store"
                lazy-validation
              >
                <v-layout wrap>
                  <v-flex xs12 sm4>
                    <v-select
                      outlined
                      dense
                      v-model="payment_mode_type.journal_type_id"
                      :items="pr_type"
                      label="Select Payment Voucher"
                      name="journal_type_id"
                      :height="25"
                    />
                    <span
                      v-if="payment_mode_type.journal_type_id"
                      style="
                        position: absolute;
                        margin-top: -18px;
                        font-size: 12px;
                        color: green;
                      "
                    >
                      <span v-if="manualSetting"
                        >Voucher No should be entered manually.</span
                      >
                      <span v-else>Voucher No generate Automatically.</span>
                    </span>
                  </v-flex>

                  <v-flex xs12 sm4>
                    <v-select
                      outlined
                      dense
                      :items="paymentMode"
                      label="Select Payment Mode"
                      v-model="payment_mode_type.ledger_head_id"
                      name="payment_mode_type"
                      :height="25"
                      :readonly="form.loading"
                    />
                  </v-flex>
                  <v-flex xs12 sm4>
                    <span v-if="manualSetting">
                      <v-text-field
                        label="Voucher No"
                        v-model="form.voucher_no"
                        name="voucher_no"
                        :error-messages="form.errors.get('voucher_no')"
                        :height="25"
                        :readonly="form.loading"
                      />
                    </span>
                    <span v-else class="voucherNo">
                      {{
                        automaticVoucherNo ? 'V.No: ' + automaticVoucherNo : ''
                      }}</span
                    >
                  </v-flex>
                  <v-flex xs12 sm4 v-if="this.payment_mode_type.mode === 15">
                    <v-select
                      outlined
                      dense
                      v-model="payment_mode_type.cash_ledger_head_id"
                      :items="cashes"
                      label="Select Cash Type"
                      name="payment_mode_type"
                      :height="25"
                      :readonly="form.loading"
                    />
                  </v-flex>
                  <v-flex xs12 sm4 v-if="payment_mode_type">
                    <v-text-field
                      outlined
                      dense
                      :readonly="form.loading"
                      v-model="payment_mode_type.cheque_no"
                      name="cheque_no"
                      label="Check No"
                      :height="25"
                      v-if="this.payment_mode_type.mode === 16"
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4 v-if="payment_mode_type">
                    <v-text-field
                      outlined
                      dense
                      label="Check Date"
                      v-model="payment_mode_type.cheque_date"
                      prepend-icon="event"
                      v-mask="'####-##-##'"
                      placeholder="YYYY-MM-DD"
                      :height="25"
                      :readonly="form.loading"
                      v-if="this.payment_mode_type.mode === 16"
                    />
                  </v-flex>
                  <v-flex xs12 sm4 v-if="this.payment_mode_type.mode === 16">
                    <v-select
                      outlined
                      dense
                      v-model="payment_mode_type.bank_ledger_head_id"
                      :items="banks"
                      label="Select Banks"
                      name="payment_mode_type"
                      :height="25"
                      :readonly="form.loading"
                    />
                  </v-flex>
                  <v-spacer></v-spacer>

                  <v-layout
                    column
                    class="rowRap"
                    :class="form.details.length > 4 ? 'fheight' : ''"
                  >
                    <v-flex md12 style="overflow: auto">
                      <v-layout
                        row
                        wrap
                        v-for="(row, key) in form.details"
                        :key="key"
                        style="width: 100%; margin-bottom: -25px"
                      >
                        <v-flex xs12 sm4>
                          <v-autocomplete
                            outlined
                            dense
                            v-model="form.details[key].ledger_head_id"
                            :items="itemsLedger[key]"
                            @focus="searchKey = key"
                            @keyup="ledgerHeadSearch"
                            @change="closingBalance(key)"
                            hide-no-data
                            item-text="name"
                            item-value="id"
                            label="Select Ledger Head"
                            placeholder="Start typing to Search"
                            height="25"
                          ></v-autocomplete>
                          <!--<v-flex xs12 sm5>-->
                          <!--<v-select :readonly="form.loading" v-model="row.ledger_head_id"-->
                          <!--:items="ledgerHeads"-->
                          <!--label="Select Ledger Head" @change="ledgerChange(row)"-->
                          <!--name="ledger_head_id" :height="25"/>-->
                          <!--</v-flex>-->
                        </v-flex>
                        <v-flex xs12 sm4>
                          <v-text-field
                            outlined
                            dense
                            :readonly="form.loading"
                            v-model="row.total_amount"
                            type="number"
                            name="amount"
                            label="Total Amount"
                            :height="25"
                            @keyup="sumNetAmount(row)"
                          ></v-text-field>
                        </v-flex>

                        <!-- <v-flex xs12 sm1 text-xs-right> -->
                        <v-btn
                          :disabled="form.loading"
                          @click="addRow"
                          class="ma-2"
                          color="green"
                          outlined
                          icon
                          small
                          style="margin-right: 0px"
                        >
                          <v-icon small class="success--text">add</v-icon>
                        </v-btn>
                        <!--<v-btn @click="addRow" outline :disabled="form.loading">-->
                        <!--<v-icon small class="mr-2"> add</v-icon>-->
                        <!--add-->
                        <!--</v-btn>-->
                        <!-- </v-flex> -->

                        <v-flex xs12 sm1 text-xs-right>
                          <v-btn
                            style="margin-top: 4px !important"
                            :disabled="form.details.length < 2"
                            @click="removeElement(row)"
                            class="ma-2"
                            color="red"
                            outlined
                            icon
                            small
                          >
                            <v-icon small class="error--text">remove</v-icon>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>

                  <div
                    v-if="form.details && form.details.length"
                    style="
                      width: 100%;
                      text-align: left;
                      padding-top: 24px;
                      margin-left: 10px;
                      border: none;
                    "
                  >
                    <v-chip label outlined color="primary"
                      >Total Amount:
                      <strong
                        >&nbsp;{{ payment_mode_type.final_amount }}</strong
                      ></v-chip
                    >
                  </div>

                  <v-flex xs12>
                    <v-textarea
                      style="
                        border: 1px solid #000;
                        padding: 20px;
                        margin: 20px 8px 0px 8px;
                      "
                      :readonly="form.loading"
                      v-model="form.narration"
                      label="Narration"
                      name="narration"
                      :error-messages="form.errors.get('narration')"
                    />
                  </v-flex>
                </v-layout>
              </v-form>
            </v-card-text>
          </v-card>
          <v-card-actions style="margin: 10px 16px 4px">
            <v-btn color="primary" outlined>
              <input
                type="file"
                id="files"
                ref="files"
                multiple
                @change="onFilePicked"
              />
            </v-btn>
          </v-card-actions>
          <v-card-actions style="margin: 0px 16px 0px">
            <v-btn
              slot="activator"
              color="primary"
              dark
              outlined
              @click="ledgerForm.dialog = true"
              :disabled="form.loading"
              style="width: 305px"
            >
              <v-icon small>add</v-icon> &nbsp;Ledger Head
            </v-btn>
            <v-spacer />
            <v-btn
              class="text-xs-right"
              color="secondary"
              :disabled="form.errors.any() || form.loading"
              @click="store(0)"
              :loading="isLoading"
              outlined
              >Save
            </v-btn>
            <v-btn
              class="text-xs-right"
              color="secondary"
              :disabled="form.errors.any() || form.loading"
              @click="store(1)"
              :loading="isSaveAndPrintLoading"
              outlined
              >Save & Print
            </v-btn>
          </v-card-actions>
        </v-card>

        <!-- Add Ledger Head -->
        <v-dialog v-model="ledgerForm.dialog" max-width="500px" persistent>
          <v-card>
            <v-card-title class="primary white--text">
              <span class="title">Ledger Head Information</span>
            </v-card-title>

            <v-card-text class="has-spinner">
              <!--<spinner light="light" :loading="ledgerForm.loading"/>-->
              <v-container fluid grid-list-sm pa-0>
                <v-form
                  ref="form"
                  @submit.prevent="store"
                  @keydown.native="ledgerForm.errors.clear($event.target.name)"
                  @keyup.enter="store"
                  lazy-validation
                >
                  <v-layout wrap>
                    <v-flex xs12 sm6>
                      <v-text-field
                        v-model="ledgerForm.name"
                        name="name"
                        label="Name"
                        :height="25"
                        autocomplete="off"
                      />
                    </v-flex>
                    <v-flex xs12 sm6 d-flex>
                      <v-autocomplete
                        v-model="ledgerForm.account_head_id"
                        :items="itemsAccount"
                        :search-input.sync="searchA"
                        hide-no-data
                        item-text="Name"
                        item-value="id"
                        label="Account Group"
                        placeholder="Start typing to Search"
                        height="25"
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12 sm6 d-flex>
                      <v-select
                        v-model="ledgerForm.drcr"
                        name="drcr"
                        :items="drcrList"
                        label="Select Opening Balance DR/CR"
                        :height="25"
                      />
                    </v-flex>
                    <v-flex xs12 sm6>
                      <v-text-field
                        v-model="ledgerForm.opening_balance"
                        name="opening_balance"
                        label="Opening Balance"
                        :height="25"
                        type="number"
                        autocomplete="off"
                      />
                    </v-flex>
                    <v-flex xs12 sm12>
                      <v-textarea
                        v-model="ledgerForm.description"
                        name="description"
                        label="Description"
                        autocomplete="off"
                      />
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-btn
                color="primary"
                dark
                outlined
                @click="accountGroupFormDialog = true"
                :disabled="accountGroupForm.loading"
              >
                <v-icon small>add</v-icon> &nbsp;Account Group
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="warning" @click="ledgerForm.reset()" outlined
                >Cancel</v-btn
              >
              <v-btn
                color="info"
                :disabled="ledgerForm.loading"
                @click="storeLedger"
                outlined
                >Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="accountGroupFormDialog" persistent max-width="400px">
          <v-card>
            <v-card-title class="title primary white--text"
              >Account Head Information</v-card-title
            >
            <v-card-text>
              <v-form
                ref="form"
                @submit.prevent="accountGroupSave"
                @keyup.enter="accountGroupSave"
                lazy-validation
              >
                <v-container grid-list-md>
                  <v-layout row wrap>
                    <v-flex xs12>
                      <v-text-field
                        v-model="accountGroupForm.name"
                        label="Name"
                        :error-messages="form.errors.get('name')"
                        :height="25"
                        autocomplete="off"
                      />
                    </v-flex>
                    <v-autocomplete
                      v-model="accountGroupForm.parent_id"
                      :items="itemsAccount"
                      :search-input.sync="searchAG"
                      hide-no-data
                      item-text="Name"
                      item-value="id"
                      label="Account Group"
                      placeholder="Start typing to Search"
                      height="25"
                      return-object
                    ></v-autocomplete>
                    <v-flex xs12 sm12>
                      <v-textarea
                        name="description"
                        :error-messages="form.errors.get('description')"
                        v-model="accountGroupForm.description"
                        label="Description"
                      />
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="warning"
                flat
                @click="
                  (accountGroupFormDialog = false), accountGroupForm.reset()
                "
                outlined
                >Cancel
              </v-btn>
              <v-btn color="success" flat @click="accountGroupSave" outlined
                >OK</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!--<notification :notification="form.notification"/>-->
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import data from './modules/data';
import computed from './modules/computed';
import mounted from './modules/mounted';
import created from './modules/created';
import methods from './modules/methods';
import watch from './modules/watch';

// import Spinner from '@/components/Partials/Spinner'
// import Notification from '@/components/Partials/Notification'

export default {
  name: 'user',
  // components: {Spinner, Notification},
  data: () => data,
  mounted: mounted,
  computed: computed,
  methods: methods,
  created: created,
  watch: watch,
};
</script>
<style lang="scss" scoped>
.voucherNo {
  position: absolute;
  margin-top: 25px;
  color: green;
}

.fheight {
  height: 45vh;
}

.rowRap {
  margin-left: 10px !important;
  margin-right: 10px !important;
  padding: 15px 15px;
  border: 1px solid black;
}
.date {
  border: 1px solid;
  width: 155px;
  margin-right: 61px;
  padding-left: 22px;
}

.v-input.v-text-field.v-text-field--placeholder.v-select.v-select--is-menu-active.v-autocomplete.v-input--is-focused.theme--light.primary--text {
  margin-bottom: -22px !important;
}

.v-input.v-text-field.v-text-field--placeholder.v-select.v-autocomplete.v-input--is-label-active.v-input--is-dirty.theme--light {
  margin-bottom: -22px;
}
.v-input.v-text-field.v-text-field--placeholder.v-select.v-autocomplete.theme--light {
  margin-bottom: -22px;
}

.balance {
  color: #777;
  font-size: 11px;
}
</style>
